/* add yellow border to all elements */
/* * { 
  border: 0.1px solid yellow;
} */

body {
  margin: 0;
  background-color: #1c1d22;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #61dafb;
  text-decoration: none;
  font-weight: 600;
}

.app-container {
  margin: 0;
  padding: 0;
}

.wrapper {
  box-sizing: border-box;
  min-width: none;

  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1200px;
}

.center {
  margin: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.normal-link {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  width: 100%;
  min-width: 100%;
  background-color: #8a41bb;
  color: white;
  display: flex;
  justify-content: center;
}

.navbar-left {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  align-items: center;
}

.navbar-right {
  display: flex;
  justify-content: flex-end;
  vertical-align: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 10px;
}

.navbar-item {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0.8rem;
  margin: 10px 10px 10px 10px;
  color: white;
  border-radius: 10px;
}

.navbar-item:hover {
  /* background-color: #61dafb; */
  color: black;
  cursor: pointer;
}

.navbar-item:hover p {
  color: black;
}

.navbar-item:hover a {
  color: black;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.navbar a:hover {
  color: #61dafb;
}

.navbar_2 {
  width: 100%;
  min-width: 100%;
  background-color: #83308a7a;
  color: white;
  display: flex;
  justify-content: center;
}

.beatmap-card {
  background-color: #55585e;
  color: white;
  margin: 10px 0px 0px 0px;
  /* padding: 10px; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  max-height: 250px;
  cursor: pointer;
}

.beatmap-card > img {
  width: 100%;
  filter: brightness(50%);
  margin: 0;
  padding: 0;
  border-radius: 10px;
}

.beatmap-card > .beatmap-card-info {
  position: absolute;
  color: white;
  left: 0;
  top: 0;
  text-shadow: #1c1d22 0px 0px 10px;
}

.beatmap-card > .beatmap-mapper {
  position: absolute;
  color: white;
  right: 0;
  bottom: 0;
  text-shadow: #1c1d22 0px 0px 10px;
}

.beatmap-card-info > .beatmap-difficulties-row {
  /* position: absolute; */
  color: white;
  /* left: 10px;
  top: 70px; */
  text-shadow: #1c1d22 0px 0px 10px;
}

.beatmap-card-info {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  border-radius: 10px;
}

.beatmap-sniper {
  color: #61dafb !important;
}

.beatmap-victim {
  color: #dd4dca !important;
}

.beatmap-difficulties-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #55585ebe;
  align-items: center;
  width: auto;
  margin: 0;
  padding: 0px;
  border-radius: 10px;
  margin-left: 10px;
}

.beatmap-difficulty-icon {
  width: 3rem;
  height: 3rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beatmap-difficulty-icon:hover {
  box-sizing: border-box;
  background-color: #55585ee5;
  border-radius: 9px;
  border: rgba(255, 255, 255, 0.9) 3px solid;
}

.beatmap-difficulty-selected {
  box-sizing: border-box;
  background-color: #55585eef;
  border-radius: 9px;
  border: rgba(255, 255, 255, 0.9) 3px solid;
}

.beatmap-card:hover {
  background-color: #61dafb;
  color: black;
}

.beatmap-card h3 {
  margin: 0;
}

.beatmap-card p {
  margin: 0;
}

.beatmap-List {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.beatmap-item {
  box-sizing: border-box;
  max-height: 10rem;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  /* margin: 10px 10px 10px 10px; */

  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.56s ease-in-out;
}

.beatmap-thumbnail {
  display: flex;
  justify-content: space-between;
  color: rgb(59, 16, 16);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100%;
  min-height: 100%;
}

.beatmap-thumbnail > .beatmap-title {
  position: absolute;
  left: 0;
  bottom: 0;
  text-shadow: #1c1d22 0px 0px 10px;
}

.beatmap-thumbnail > .beatmap-difficulty {
  position: absolute;
  right: 0;
  top: 0;
  text-shadow: #1c1d22 0px 0px 8px;
}

.beatmap-thumbnail > img {
  filter: brightness(50%);
  min-width: 100%;
  min-height: 100%;
}

.beatmap-title {
  align-self: flex-end;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 2rem;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 0;
}

.beatmap-difficulty {
  align-self: flex-end;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
}

.beatmap-mapper {
  align-self: flex-end;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
}

.snipe-item {
  position: relative;
  margin: 10px 0px 0px 0px;
}

.beatmap-snipe {
  align-self: flex-end;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  /* line-height: 1; */
  font-weight: 600;
}

.snipe-item > .beatmap-snipe {
  margin: 10px 0px 0px 0px;
  position: absolute;
  left: 0;
  top: 0;
  text-shadow: #1c1d22 0px 0px 3px;
}

.beatmap-item a {
  color: white;
  text-decoration: none;
}

.beatmap-item a:hover {
  color: #61dafb;
}

.beatmap-item p {
  margin: 0;
}

.beatmap-item p:first-child {
  margin-right: 10px;
}

.beatmap-item p:last-child {
  margin-left: 10px;
}

.beatmap-item:hover {
  background-color: #61dafb;
  color: black;
  cursor: pointer;
  box-shadow: 0 0px 38px 3px rgba(212, 48, 190, 0.377),
    0 0px 46px 8px rgba(212, 48, 190, 0.377),
    0 0px 15px -7px rgba(212, 48, 190, 0.377);
}

.beatmap-item:hover p {
  color: black;
}

.beatmap-item:hover a {
  color: black;
}

.index-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
}

.index-selector-arrow {
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  transition: 300ms;
}

.index-selector-arrow:hover {
  color: #61dafb;
}

.index-selector-arrow > *:hover {
  color: #61dafb;
}

.index-selector-arrow-right {
  transform: rotate(180deg);
}

.table-row {
  background-color: #55585e73;
  display: table-row;
  transition: 300ms;
  color: white;
}

.table-row:hover {
  background-color: #61dafb;
  color: black;
  cursor: pointer;
}

.table-row:hover .player-name {
  color: white;
}

.table-row td {
  padding: 7px;
  /* display: table-cell; */
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.table-row > td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.table-row > td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.table-row > .player-name:hover {
  text-decoration: underline;
  color: white;
}

table {
  border-collapse: separate;
  border-spacing: 0 3px;
  width: 100%;
  display: table;
  table-layout: fixed;
  text-align: center;
  background-color: transparent;
  white-space: nowrap;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

thead > tr {
  display: table-row;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: middle;
  border-color: inherit;
}

.score-detail-player {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin: 10px 0px 10px 0px;
}

.score-detail-player > .score-detail-player-info {
  margin-left: 10px;
  padding: 0px;
}

.score-detail-player-info .player-name {
  font-weight: 750;
  font-size: 3rem;
  color: #61dafb;
  cursor: pointer;
}

.score-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
  /* width: max-content; */
}

.score-detail > .score-detail-score {
  margin-left: 10px;
  padding: 0px;
  justify-content: right;
  text-align: right;
}

.score-detail-avatar {
  overflow: hidden;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  margin: 0px;
  padding: 0px;
}

.score-detail-avatar img {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
}

.sniper-name {
  font-weight: 600;
  color: #61dafb;
  cursor: pointer;
}

.sniper-name:hover {
  color: #61dafb;
  text-decoration: underline;
}

.victim-name {
  color: #d430be;
  cursor: pointer;
}

.victim-name:hover {
  color: #d430be;
  text-decoration: underline;
}

.snipe-timestamp {
  color: #61fb94;
  font-size: 0.8rem;
  font-weight: 600;
}

.snipe-map {
  color: #dffb61;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}

.snipe-map:hover {
  color: #dffb61;
  text-decoration: underline;
}

.user-profile-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
  cursor: pointer;
}

.user-profile-header > .user-profile-avatar {
  margin-right: 10px;
  width: 200px;
  min-width: 200px;
  height: 200px;
  padding: 0px;
  overflow: hidden;
  border-radius: 100%;
}

.user-profile-header > .user-profile-header-info {
  margin-left: 10px;
  padding: 0px;
}

.search-bar__input {
  box-sizing: border-box;
  background-color: #c2c7d173;
  border: 1px solid #55585e73;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  height: 2.5rem;
  padding: 0.5rem;
  width: 50%;
  transition: border-color 0.2s ease-in-out;
}

.search-bar__input:focus {
  outline: none;
  border-color: #61dafb;
}

.search-bar__input::placeholder {
  color: #fff;
}

.result-list-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
}

.result-list-group-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 10px 10px 0px;
}

.player-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 10px 0px 0px;
  background-color: #55585e73;
  width: 400px;
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
  transition: 300ms;
  overflow: hidden;
}

.player-card:hover {
  background-color: #61dafb;
  color: white;
}

.player-card > .player-card-avatar {
  margin-right: 10px;
  padding: 0px;
  overflow: hidden;
  height: 150px;
  width: 150px;
  min-width: 150px;
  min-height: 150px;
  border-radius: 10px;
}

.player-card-avatar > img {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}

.player-card > .player-card-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding: 0px;
}

.player-card-info > .player-card-name {
  font-weight: 600;
  font-size: xx-large;
  color: #fff;
  text-overflow: ellipsis;
}

.mapset-card {
  display: grid;
  align-items: center;
  margin: 10px 10px 0px 0px;
  background-color: #55585e73;
  width: 400px;
  height: 150px;
  position: relative;

  border-radius: 10px;
  cursor: pointer;
  transition: 300ms;
  overflow: hidden;
}

.mapset-card:hover {
  box-shadow: 0px 0px 10px 5px #61dafb;
  /*lift the box*/
  transform: translate(0px, -5px);
}

.mapset-card > .mapset-card-cover-header {
  display: contents;
  overflow: hidden;
  /* position: relative; */
}

.mapset-card-cover {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin-right: 10px;
  padding: 0px;
  overflow: hidden;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-image: url("https://osu.ppy.sh/assets/images/default-bg.7594e945.png");
  filter: brightness(50%);
}

.mapset-card-cover:hover {
  filter: brightness(100%) blur(5px);
  transition: 300ms;
}

.mapset-card-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding: 0px;
  position: relative;
  text-shadow: 0px 0px 10px #000;
  pointer-events: none;
}

.mapset-card-info__title {
  font-weight: 600;
  font-size: x-large;
  color: #fff;
  text-overflow: ellipsis;
}

.mapset-card-info__mapper {
  font-weight: 600;
  font-size: large;
  color: #fff;
  text-overflow: ellipsis;
}

.sort-selector {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
}

button {
  margin: 0px 0.3rem 0px 0.3rem;
  padding: 10px;
  border-radius: 10px;
  background-color: #55585e00;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: 300ms;
  border: 1px solid #55585e73;
}

.active {
  border: #61dafb solid 1px !important;
}

button:hover {
  background-color: #55585e73;
  color: #fff;
}

button:active {
  background-color: #61dafb;
}

.toggled {
  background-color: #61dafb;
  text-shadow: 0px 0px 10px #000;
}

.toggled:hover {
  background-color: #296170;
}

.snipe-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
}

.hof-container {
  display: grid;
  /* grid-template-rows: repeat(4, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
}

.hof-element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  font-weight: 700;

  cursor: pointer;
  transition: 300ms;
  /* overflow: hidden; */
}

.hof-element > a {
  color: #fff;
  text-decoration: none;
}

.hof-card {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  margin: 10px 0px 0px 0px;
  background-color: #55585e73;
  max-width: 256px;
  border-radius: 10px;
  cursor: pointer;
  transition: 300ms;
  overflow: hidden;
  box-shadow: 0px 0px 10px 5px #13141473;
}

.hof-card_avatar {
  width: 256px;
  /* position: relative; */
}

.hof-card:hover {
  background-color: #61dafb;
  transform: translate(0px, -5px);
  /* box-shadow: 0px 0px 10px 5px #61dafb; */
}

.hof-card_content {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  margin: 0px 10px 0px 10px;
  font-weight: normal;
  text-align: center;
}

.hof-card_content > .player-name {
  font-weight: 600;
  margin: 5px;
  font-size: x-large;
  color: #fff;
  text-overflow: ellipsis;
}

.hof-card_content > .map-name {
  font-weight: 700;
  margin: 5px;
  /* font-size: x-large; */
  color: #fff;
  text-overflow: ellipsis;
}

.hof-card_content > .map-name:hover {
  text-decoration: underline;
}

.snowflake {
  --size: 1vh;
  z-index: -1;
  width: var(--size);
  height: var(--size);
  background: white;
  filter: drop-shadow(0 0 10px white);
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  top: -5vh;
}

.snowWrapper {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  position: fixed;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* box-sizing: border-box; */
}

@keyframes snowfall {
  0% {
    transform: translate(var(--left-ini), 0);
  }
  100% {
    transform: translate(var(--left-end), 110vh);
  }
}

.snowflake:nth-child(1) {
  --size: 0.2vw;
  --left-ini: -8vw;
  --left-end: -3vw;
  left: 37vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -1s;
  opacity: 0.0249;
}
.snowflake:nth-child(2) {
  --size: 0.4vw;
  --left-ini: -2vw;
  --left-end: 4vw;
  left: 63vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -4s;
  opacity: 0.3328;
}
.snowflake:nth-child(3) {
  --size: 0.3vw;
  --left-ini: 5vw;
  --left-end: -1vw;
  left: 100vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -1s;
  opacity: 0.6629;
}
.snowflake:nth-child(4) {
  --size: 0.3vw;
  --left-ini: -4vw;
  --left-end: -4vw;
  left: 9vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -10s;
  opacity: 0.4064;
}
.snowflake:nth-child(5) {
  --size: 0.2vw;
  --left-ini: 3vw;
  --left-end: 8vw;
  left: 65vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -8s;
  opacity: 0.3426;
}
.snowflake:nth-child(6) {
  --size: 0.3vw;
  --left-ini: -1vw;
  --left-end: 2vw;
  left: 23vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -10s;
  opacity: 0.8387;
}
.snowflake:nth-child(7) {
  --size: 0.5vw;
  --left-ini: 7vw;
  --left-end: 0vw;
  left: 31vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -2s;
  opacity: 0.2649;
}
.snowflake:nth-child(8) {
  --size: 0.3vw;
  --left-ini: 8vw;
  --left-end: 7vw;
  left: 38vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -8s;
  opacity: 0.0769;
}
.snowflake:nth-child(9) {
  --size: 0.5vw;
  --left-ini: 7vw;
  --left-end: -6vw;
  left: 30vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -10s;
  opacity: 0.7077;
}
.snowflake:nth-child(10) {
  --size: 0.4vw;
  --left-ini: 5vw;
  --left-end: -6vw;
  left: 27vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -2s;
  opacity: 0.8623;
}
.snowflake:nth-child(11) {
  --size: 0.2vw;
  --left-ini: -7vw;
  --left-end: -6vw;
  left: 26vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -6s;
  opacity: 0.7776;
}
.snowflake:nth-child(12) {
  --size: 0.3vw;
  --left-ini: 6vw;
  --left-end: 4vw;
  left: 70vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -2s;
  opacity: 0.1955;
}
.snowflake:nth-child(13) {
  --size: 0.1vw;
  --left-ini: -5vw;
  --left-end: 0vw;
  left: 19vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -7s;
  opacity: 0.1284;
}
.snowflake:nth-child(14) {
  --size: 0.5vw;
  --left-ini: -7vw;
  --left-end: -3vw;
  left: 3vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -6s;
  opacity: 0.1819;
}
.snowflake:nth-child(15) {
  --size: 0.3vw;
  --left-ini: 10vw;
  --left-end: -3vw;
  left: 8vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -1s;
  opacity: 0.5034;
}
.snowflake:nth-child(16) {
  --size: 0.2vw;
  --left-ini: 8vw;
  --left-end: 8vw;
  left: 20vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -10s;
  opacity: 0.9483;
}
.snowflake:nth-child(17) {
  --size: 0.5vw;
  --left-ini: -4vw;
  --left-end: -5vw;
  left: 53vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -4s;
  opacity: 0.1188;
}
.snowflake:nth-child(18) {
  --size: 0.1vw;
  --left-ini: 5vw;
  --left-end: 5vw;
  left: 33vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -3s;
  opacity: 0.0483;
}
.snowflake:nth-child(19) {
  --size: 0.5vw;
  --left-ini: -8vw;
  --left-end: -5vw;
  left: 17vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -5s;
  opacity: 0.9332;
}
.snowflake:nth-child(20) {
  --size: 0.1vw;
  --left-ini: -7vw;
  --left-end: -3vw;
  left: 20vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -7s;
  opacity: 0.0938;
}
.snowflake:nth-child(21) {
  --size: 0.3vw;
  --left-ini: 10vw;
  --left-end: 9vw;
  left: 58vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -5s;
  opacity: 0.0133;
}
.snowflake:nth-child(22) {
  --size: 0.3vw;
  --left-ini: 7vw;
  --left-end: 0vw;
  left: 42vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -7s;
  opacity: 0.3531;
}
.snowflake:nth-child(23) {
  --size: 0.4vw;
  --left-ini: 0vw;
  --left-end: -8vw;
  left: 68vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -4s;
  opacity: 0.3261;
}
.snowflake:nth-child(24) {
  --size: 0.3vw;
  --left-ini: -1vw;
  --left-end: -4vw;
  left: 50vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -8s;
  opacity: 0.2297;
}
.snowflake:nth-child(25) {
  --size: 0.1vw;
  --left-ini: 1vw;
  --left-end: -8vw;
  left: 43vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -4s;
  opacity: 0.7773;
}
.snowflake:nth-child(26) {
  --size: 0.2vw;
  --left-ini: -7vw;
  --left-end: -6vw;
  left: 95vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -3s;
  opacity: 0.1368;
}
.snowflake:nth-child(27) {
  --size: 0.1vw;
  --left-ini: -8vw;
  --left-end: -9vw;
  left: 43vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -7s;
  opacity: 0.5427;
}
.snowflake:nth-child(28) {
  --size: 0.4vw;
  --left-ini: -6vw;
  --left-end: -8vw;
  left: 100vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -7s;
  opacity: 0.1017;
}
.snowflake:nth-child(29) {
  --size: 0.5vw;
  --left-ini: 2vw;
  --left-end: -6vw;
  left: 55vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -1s;
  opacity: 0.8725;
}
.snowflake:nth-child(30) {
  --size: 0.1vw;
  --left-ini: 10vw;
  --left-end: 2vw;
  left: 68vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -2s;
  opacity: 0.0669;
}
.snowflake:nth-child(31) {
  --size: 0.4vw;
  --left-ini: 1vw;
  --left-end: 9vw;
  left: 54vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -9s;
  opacity: 0.0653;
}
.snowflake:nth-child(32) {
  --size: 0.1vw;
  --left-ini: 0vw;
  --left-end: 8vw;
  left: 46vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -6s;
  opacity: 0.2708;
}
.snowflake:nth-child(33) {
  --size: 0.1vw;
  --left-ini: 7vw;
  --left-end: 5vw;
  left: 21vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -9s;
  opacity: 0.3372;
}
.snowflake:nth-child(34) {
  --size: 0.5vw;
  --left-ini: 6vw;
  --left-end: -9vw;
  left: 34vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -9s;
  opacity: 0.6447;
}
.snowflake:nth-child(35) {
  --size: 0.4vw;
  --left-ini: 8vw;
  --left-end: 4vw;
  left: 25vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -8s;
  opacity: 0.6822;
}
.snowflake:nth-child(36) {
  --size: 0.1vw;
  --left-ini: -7vw;
  --left-end: -7vw;
  left: 66vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -6s;
  opacity: 0.7698;
}
.snowflake:nth-child(37) {
  --size: 0.3vw;
  --left-ini: -9vw;
  --left-end: 4vw;
  left: 52vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -4s;
  opacity: 0.0331;
}
.snowflake:nth-child(38) {
  --size: 0.4vw;
  --left-ini: -1vw;
  --left-end: -8vw;
  left: 89vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -2s;
  opacity: 0.6382;
}
.snowflake:nth-child(39) {
  --size: 0.2vw;
  --left-ini: 2vw;
  --left-end: -2vw;
  left: 100vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -10s;
  opacity: 0.3379;
}
.snowflake:nth-child(40) {
  --size: 0.3vw;
  --left-ini: 8vw;
  --left-end: 5vw;
  left: 34vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -8s;
  opacity: 0.7797;
}
.snowflake:nth-child(41) {
  --size: 0.5vw;
  --left-ini: 8vw;
  --left-end: -3vw;
  left: 53vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -5s;
  opacity: 0.8477;
}
.snowflake:nth-child(42) {
  --size: 0.3vw;
  --left-ini: -2vw;
  --left-end: 4vw;
  left: 15vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -8s;
  opacity: 0.4142;
}
.snowflake:nth-child(43) {
  --size: 0.1vw;
  --left-ini: -3vw;
  --left-end: -1vw;
  left: 100vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -8s;
  opacity: 0.1763;
}
.snowflake:nth-child(44) {
  --size: 0.2vw;
  --left-ini: -1vw;
  --left-end: 9vw;
  left: 14vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -9s;
  opacity: 0.1161;
}
.snowflake:nth-child(45) {
  --size: 0.2vw;
  --left-ini: 8vw;
  --left-end: -8vw;
  left: 70vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -1s;
  opacity: 0.4409;
}
.snowflake:nth-child(46) {
  --size: 0.2vw;
  --left-ini: -4vw;
  --left-end: 4vw;
  left: 87vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -2s;
  opacity: 0.3607;
}
.snowflake:nth-child(47) {
  --size: 0.3vw;
  --left-ini: 7vw;
  --left-end: 2vw;
  left: 71vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -5s;
  opacity: 0.4928;
}
.snowflake:nth-child(48) {
  --size: 0.3vw;
  --left-ini: -4vw;
  --left-end: 3vw;
  left: 45vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -7s;
  opacity: 0.1254;
}
.snowflake:nth-child(49) {
  --size: 0.4vw;
  --left-ini: -2vw;
  --left-end: 7vw;
  left: 33vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -9s;
  opacity: 0.0022;
}
.snowflake:nth-child(50) {
  --size: 0.5vw;
  --left-ini: 2vw;
  --left-end: -7vw;
  left: 42vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -5s;
  opacity: 0.5994;
}
.snowflake:nth-child(51) {
  --size: 0.2vw;
  --left-ini: -6vw;
  --left-end: -8vw;
  left: 45vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -4s;
  opacity: 0.7662;
}
.snowflake:nth-child(52) {
  --size: 0.2vw;
  --left-ini: -1vw;
  --left-end: -6vw;
  left: 35vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -10s;
  opacity: 0.6655;
}
.snowflake:nth-child(53) {
  --size: 0.3vw;
  --left-ini: 4vw;
  --left-end: 9vw;
  left: 100vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -7s;
  opacity: 0.8714;
}
.snowflake:nth-child(54) {
  --size: 0.1vw;
  --left-ini: -4vw;
  --left-end: -2vw;
  left: 29vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -8s;
  opacity: 0.8804;
}
.snowflake:nth-child(55) {
  --size: 0.2vw;
  --left-ini: 6vw;
  --left-end: -3vw;
  left: 36vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -6s;
  opacity: 0.5459;
}
.snowflake:nth-child(56) {
  --size: 0.1vw;
  --left-ini: -7vw;
  --left-end: -6vw;
  left: 1vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -10s;
  opacity: 0.3325;
}
.snowflake:nth-child(57) {
  --size: 0.1vw;
  --left-ini: 0vw;
  --left-end: 8vw;
  left: 25vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -5s;
  opacity: 0.7941;
}
.snowflake:nth-child(58) {
  --size: 0.5vw;
  --left-ini: -5vw;
  --left-end: -9vw;
  left: 19vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -2s;
  opacity: 0.4619;
}
.snowflake:nth-child(59) {
  --size: 0.2vw;
  --left-ini: -5vw;
  --left-end: -1vw;
  left: 69vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -7s;
  opacity: 0.6647;
}
.snowflake:nth-child(60) {
  --size: 0.4vw;
  --left-ini: 4vw;
  --left-end: 4vw;
  left: 86vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -1s;
  opacity: 0.9472;
}
.snowflake:nth-child(61) {
  --size: 0.3vw;
  --left-ini: 8vw;
  --left-end: -5vw;
  left: 43vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -1s;
  opacity: 0.7995;
}
.snowflake:nth-child(62) {
  --size: 0.2vw;
  --left-ini: -3vw;
  --left-end: -1vw;
  left: 66vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -8s;
  opacity: 0.1796;
}
.snowflake:nth-child(63) {
  --size: 0.5vw;
  --left-ini: -2vw;
  --left-end: 7vw;
  left: 67vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -4s;
  opacity: 0.9988;
}
.snowflake:nth-child(64) {
  --size: 0.1vw;
  --left-ini: -4vw;
  --left-end: 5vw;
  left: 87vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -6s;
  opacity: 0.7871;
}
.snowflake:nth-child(65) {
  --size: 0.5vw;
  --left-ini: -8vw;
  --left-end: 9vw;
  left: 14vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -9s;
  opacity: 0.9886;
}
.snowflake:nth-child(66) {
  --size: 0.5vw;
  --left-ini: 6vw;
  --left-end: -5vw;
  left: 32vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -10s;
  opacity: 0.3588;
}
.snowflake:nth-child(67) {
  --size: 0.4vw;
  --left-ini: -5vw;
  --left-end: -4vw;
  left: 16vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -1s;
  opacity: 0.2493;
}
.snowflake:nth-child(68) {
  --size: 0.2vw;
  --left-ini: 9vw;
  --left-end: 0vw;
  left: 96vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -7s;
  opacity: 0.7897;
}
.snowflake:nth-child(69) {
  --size: 0.4vw;
  --left-ini: -2vw;
  --left-end: 6vw;
  left: 74vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -6s;
  opacity: 0.8926;
}
.snowflake:nth-child(70) {
  --size: 0.4vw;
  --left-ini: 2vw;
  --left-end: 1vw;
  left: 25vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -1s;
  opacity: 0.4502;
}
.snowflake:nth-child(71) {
  --size: 0.1vw;
  --left-ini: 10vw;
  --left-end: 4vw;
  left: 94vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -7s;
  opacity: 0.2671;
}
.snowflake:nth-child(72) {
  --size: 0.1vw;
  --left-ini: 7vw;
  --left-end: -9vw;
  left: 84vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -2s;
  opacity: 0.429;
}
.snowflake:nth-child(73) {
  --size: 0.3vw;
  --left-ini: 3vw;
  --left-end: -6vw;
  left: 59vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -10s;
  opacity: 0.626;
}
.snowflake:nth-child(74) {
  --size: 0.5vw;
  --left-ini: 7vw;
  --left-end: 10vw;
  left: 67vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -6s;
  opacity: 0.6532;
}
.snowflake:nth-child(75) {
  --size: 0.3vw;
  --left-ini: 8vw;
  --left-end: -1vw;
  left: 87vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -1s;
  opacity: 0.3667;
}
.snowflake:nth-child(76) {
  --size: 0.4vw;
  --left-ini: 3vw;
  --left-end: 1vw;
  left: 69vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -10s;
  opacity: 0.7981;
}
.snowflake:nth-child(77) {
  --size: 0.3vw;
  --left-ini: -3vw;
  --left-end: 7vw;
  left: 46vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -10s;
  opacity: 0.7732;
}
.snowflake:nth-child(78) {
  --size: 0.3vw;
  --left-ini: 9vw;
  --left-end: 5vw;
  left: 63vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -8s;
  opacity: 0.2715;
}
.snowflake:nth-child(79) {
  --size: 0.2vw;
  --left-ini: -2vw;
  --left-end: -8vw;
  left: 44vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -6s;
  opacity: 0.0013;
}
.snowflake:nth-child(80) {
  --size: 0.5vw;
  --left-ini: -7vw;
  --left-end: 5vw;
  left: 14vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -6s;
  opacity: 0.8294;
}
.snowflake:nth-child(81) {
  --size: 0.2vw;
  --left-ini: 8vw;
  --left-end: 10vw;
  left: 63vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -8s;
  opacity: 0.4417;
}
.snowflake:nth-child(82) {
  --size: 0.1vw;
  --left-ini: -1vw;
  --left-end: 4vw;
  left: 38vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -4s;
  opacity: 0.1909;
}
.snowflake:nth-child(83) {
  --size: 0.4vw;
  --left-ini: -6vw;
  --left-end: 3vw;
  left: 86vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -8s;
  opacity: 0.0753;
}
.snowflake:nth-child(84) {
  --size: 0.2vw;
  --left-ini: -7vw;
  --left-end: 8vw;
  left: 36vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -2s;
  opacity: 0.1022;
}
.snowflake:nth-child(85) {
  --size: 0.5vw;
  --left-ini: 3vw;
  --left-end: 4vw;
  left: 70vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -2s;
  opacity: 0.2719;
}
.snowflake:nth-child(86) {
  --size: 0.4vw;
  --left-ini: 6vw;
  --left-end: 8vw;
  left: 71vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -4s;
  opacity: 0.008;
}
.snowflake:nth-child(87) {
  --size: 0.1vw;
  --left-ini: -6vw;
  --left-end: -3vw;
  left: 73vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -4s;
  opacity: 0.6726;
}
.snowflake:nth-child(88) {
  --size: 0.2vw;
  --left-ini: 6vw;
  --left-end: -7vw;
  left: 93vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -4s;
  opacity: 0.9953;
}
.snowflake:nth-child(89) {
  --size: 0.4vw;
  --left-ini: -1vw;
  --left-end: 0vw;
  left: 58vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -4s;
  opacity: 0.0235;
}
.snowflake:nth-child(90) {
  --size: 0.2vw;
  --left-ini: 1vw;
  --left-end: -1vw;
  left: 84vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -2s;
  opacity: 0.8985;
}
.snowflake:nth-child(91) {
  --size: 0.2vw;
  --left-ini: 9vw;
  --left-end: 1vw;
  left: 90vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -6s;
  opacity: 0.1591;
}
.snowflake:nth-child(92) {
  --size: 0.4vw;
  --left-ini: -8vw;
  --left-end: 3vw;
  left: 65vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -3s;
  opacity: 0.1664;
}
.snowflake:nth-child(93) {
  --size: 0.4vw;
  --left-ini: -9vw;
  --left-end: -2vw;
  left: 51vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -5s;
  opacity: 0.626;
}
.snowflake:nth-child(94) {
  --size: 0.3vw;
  --left-ini: 7vw;
  --left-end: -4vw;
  left: 41vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -6s;
  opacity: 0.4353;
}
.snowflake:nth-child(95) {
  --size: 0.4vw;
  --left-ini: -8vw;
  --left-end: -4vw;
  left: 29vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -8s;
  opacity: 0.983;
}
.snowflake:nth-child(96) {
  --size: 0.3vw;
  --left-ini: 7vw;
  --left-end: -9vw;
  left: 53vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -10s;
  opacity: 0.1972;
}
.snowflake:nth-child(97) {
  --size: 0.4vw;
  --left-ini: 8vw;
  --left-end: 8vw;
  left: 58vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -8s;
  opacity: 0.6681;
}
.snowflake:nth-child(98) {
  --size: 0.1vw;
  --left-ini: -1vw;
  --left-end: 1vw;
  left: 96vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -10s;
  opacity: 0.2608;
}
.snowflake:nth-child(99) {
  --size: 0.5vw;
  --left-ini: 6vw;
  --left-end: -8vw;
  left: 46vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -10s;
  opacity: 0.2246;
}
.snowflake:nth-child(100) {
  --size: 0.4vw;
  --left-ini: 5vw;
  --left-end: 7vw;
  left: 88vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -4s;
  opacity: 0.8791;
}
.snowflake:nth-child(101) {
  --size: 0.1vw;
  --left-ini: -3vw;
  --left-end: -4vw;
  left: 70vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -8s;
  opacity: 0.2716;
}
.snowflake:nth-child(102) {
  --size: 0.2vw;
  --left-ini: -2vw;
  --left-end: 0vw;
  left: 24vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -7s;
  opacity: 0.8322;
}
.snowflake:nth-child(103) {
  --size: 0.4vw;
  --left-ini: 4vw;
  --left-end: -1vw;
  left: 9vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -4s;
  opacity: 0.6747;
}
.snowflake:nth-child(104) {
  --size: 0.2vw;
  --left-ini: -2vw;
  --left-end: 7vw;
  left: 97vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -3s;
  opacity: 0.0165;
}
.snowflake:nth-child(105) {
  --size: 0.1vw;
  --left-ini: -7vw;
  --left-end: 5vw;
  left: 100vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -8s;
  opacity: 0.668;
}
.snowflake:nth-child(106) {
  --size: 0.3vw;
  --left-ini: -5vw;
  --left-end: 0vw;
  left: 60vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -9s;
  opacity: 0.9185;
}
.snowflake:nth-child(107) {
  --size: 0.4vw;
  --left-ini: -8vw;
  --left-end: -5vw;
  left: 4vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -5s;
  opacity: 0.6338;
}
.snowflake:nth-child(108) {
  --size: 0.3vw;
  --left-ini: -2vw;
  --left-end: -8vw;
  left: 18vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -3s;
  opacity: 0.0626;
}
.snowflake:nth-child(109) {
  --size: 0.1vw;
  --left-ini: 4vw;
  --left-end: 8vw;
  left: 16vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -9s;
  opacity: 0.7398;
}
.snowflake:nth-child(110) {
  --size: 0.4vw;
  --left-ini: 1vw;
  --left-end: 8vw;
  left: 100vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -6s;
  opacity: 0.0505;
}
.snowflake:nth-child(111) {
  --size: 0.3vw;
  --left-ini: 7vw;
  --left-end: 4vw;
  left: 60vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -8s;
  opacity: 0.7425;
}
.snowflake:nth-child(112) {
  --size: 0.5vw;
  --left-ini: 1vw;
  --left-end: 8vw;
  left: 65vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -6s;
  opacity: 0.7487;
}
.snowflake:nth-child(113) {
  --size: 0.2vw;
  --left-ini: -1vw;
  --left-end: 0vw;
  left: 35vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -3s;
  opacity: 0.4634;
}
.snowflake:nth-child(114) {
  --size: 0.4vw;
  --left-ini: 6vw;
  --left-end: 0vw;
  left: 7vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -1s;
  opacity: 0.8136;
}
.snowflake:nth-child(115) {
  --size: 0.4vw;
  --left-ini: -2vw;
  --left-end: -7vw;
  left: 47vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -6s;
  opacity: 0.9901;
}
.snowflake:nth-child(116) {
  --size: 0.4vw;
  --left-ini: -3vw;
  --left-end: -3vw;
  left: 88vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -5s;
  opacity: 0.0885;
}
.snowflake:nth-child(117) {
  --size: 0.4vw;
  --left-ini: -1vw;
  --left-end: 8vw;
  left: 2vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -2s;
  opacity: 0.6267;
}
.snowflake:nth-child(118) {
  --size: 0.5vw;
  --left-ini: -2vw;
  --left-end: -9vw;
  left: 71vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -6s;
  opacity: 0.4961;
}
.snowflake:nth-child(119) {
  --size: 0.5vw;
  --left-ini: -3vw;
  --left-end: -9vw;
  left: 61vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -8s;
  opacity: 0.941;
}
.snowflake:nth-child(120) {
  --size: 0.1vw;
  --left-ini: 2vw;
  --left-end: -3vw;
  left: 69vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -6s;
  opacity: 0.4133;
}
.snowflake:nth-child(121) {
  --size: 0.1vw;
  --left-ini: -5vw;
  --left-end: -5vw;
  left: 100vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -7s;
  opacity: 0.6964;
}
.snowflake:nth-child(122) {
  --size: 0.3vw;
  --left-ini: 1vw;
  --left-end: 9vw;
  left: 68vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -7s;
  opacity: 0.3203;
}
.snowflake:nth-child(123) {
  --size: 0.2vw;
  --left-ini: 1vw;
  --left-end: -5vw;
  left: 59vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -2s;
  opacity: 0.7761;
}
.snowflake:nth-child(124) {
  --size: 0.4vw;
  --left-ini: 4vw;
  --left-end: -3vw;
  left: 17vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -9s;
  opacity: 0.0348;
}
.snowflake:nth-child(125) {
  --size: 0.3vw;
  --left-ini: -7vw;
  --left-end: -9vw;
  left: 69vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -2s;
  opacity: 0.5942;
}
.snowflake:nth-child(126) {
  --size: 0.3vw;
  --left-ini: 4vw;
  --left-end: 1vw;
  left: 21vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -5s;
  opacity: 0.2799;
}
.snowflake:nth-child(127) {
  --size: 0.5vw;
  --left-ini: 3vw;
  --left-end: -3vw;
  left: 73vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -3s;
  opacity: 0.1409;
}
.snowflake:nth-child(128) {
  --size: 0.2vw;
  --left-ini: -1vw;
  --left-end: -1vw;
  left: 54vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -6s;
  opacity: 0.3465;
}
.snowflake:nth-child(129) {
  --size: 0.5vw;
  --left-ini: 1vw;
  --left-end: -5vw;
  left: 57vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -9s;
  opacity: 0.4499;
}
.snowflake:nth-child(130) {
  --size: 0.1vw;
  --left-ini: -7vw;
  --left-end: -8vw;
  left: 16vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -5s;
  opacity: 0.0022;
}
.snowflake:nth-child(131) {
  --size: 0.5vw;
  --left-ini: 2vw;
  --left-end: 6vw;
  left: 93vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -7s;
  opacity: 0.7678;
}
.snowflake:nth-child(132) {
  --size: 0.4vw;
  --left-ini: -4vw;
  --left-end: 6vw;
  left: 68vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -2s;
  opacity: 0.1624;
}
.snowflake:nth-child(133) {
  --size: 0.1vw;
  --left-ini: -2vw;
  --left-end: 8vw;
  left: 39vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -9s;
  opacity: 0.2271;
}
.snowflake:nth-child(134) {
  --size: 0.5vw;
  --left-ini: 7vw;
  --left-end: -8vw;
  left: 4vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -6s;
  opacity: 0.904;
}
.snowflake:nth-child(135) {
  --size: 0.2vw;
  --left-ini: -7vw;
  --left-end: -5vw;
  left: 100vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -10s;
  opacity: 0.7866;
}
.snowflake:nth-child(136) {
  --size: 0.1vw;
  --left-ini: -6vw;
  --left-end: -1vw;
  left: 32vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -3s;
  opacity: 0.6067;
}
.snowflake:nth-child(137) {
  --size: 0.4vw;
  --left-ini: 0vw;
  --left-end: -5vw;
  left: 30vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -9s;
  opacity: 0.95;
}
.snowflake:nth-child(138) {
  --size: 0.3vw;
  --left-ini: -4vw;
  --left-end: -4vw;
  left: 20vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -5s;
  opacity: 0.4905;
}
.snowflake:nth-child(139) {
  --size: 0.4vw;
  --left-ini: 3vw;
  --left-end: -3vw;
  left: 75vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -1s;
  opacity: 0.3844;
}
.snowflake:nth-child(140) {
  --size: 0.2vw;
  --left-ini: 10vw;
  --left-end: -6vw;
  left: 75vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -10s;
  opacity: 0.3502;
}
.snowflake:nth-child(141) {
  --size: 0.3vw;
  --left-ini: 6vw;
  --left-end: 0vw;
  left: 46vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -6s;
  opacity: 0.2868;
}
.snowflake:nth-child(142) {
  --size: 0.2vw;
  --left-ini: -2vw;
  --left-end: 9vw;
  left: 79vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -9s;
  opacity: 0.0002;
}
.snowflake:nth-child(143) {
  --size: 0.3vw;
  --left-ini: -8vw;
  --left-end: 3vw;
  left: 85vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -8s;
  opacity: 0.2476;
}
.snowflake:nth-child(144) {
  --size: 0.3vw;
  --left-ini: -7vw;
  --left-end: 0vw;
  left: 99vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -5s;
  opacity: 0.0234;
}
.snowflake:nth-child(145) {
  --size: 0.1vw;
  --left-ini: 9vw;
  --left-end: 2vw;
  left: 26vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -7s;
  opacity: 0.6565;
}
.snowflake:nth-child(146) {
  --size: 0.1vw;
  --left-ini: -8vw;
  --left-end: 6vw;
  left: 7vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -10s;
  opacity: 0.9331;
}
.snowflake:nth-child(147) {
  --size: 0.3vw;
  --left-ini: -8vw;
  --left-end: -1vw;
  left: 90vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -6s;
  opacity: 0.6123;
}
.snowflake:nth-child(148) {
  --size: 0.2vw;
  --left-ini: -3vw;
  --left-end: -9vw;
  left: 100vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -2s;
  opacity: 0.0661;
}
.snowflake:nth-child(149) {
  --size: 0.2vw;
  --left-ini: -6vw;
  --left-end: 6vw;
  left: 52vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -8s;
  opacity: 0.9074;
}
.snowflake:nth-child(150) {
  --size: 0.3vw;
  --left-ini: -5vw;
  --left-end: 7vw;
  left: 22vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -10s;
  opacity: 0.3435;
}
.snowflake:nth-child(151) {
  --size: 0.4vw;
  --left-ini: -1vw;
  --left-end: -7vw;
  left: 28vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -2s;
  opacity: 0.7019;
}
.snowflake:nth-child(152) {
  --size: 0.4vw;
  --left-ini: -7vw;
  --left-end: -3vw;
  left: 68vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -7s;
  opacity: 0.5424;
}
.snowflake:nth-child(153) {
  --size: 0.2vw;
  --left-ini: 0vw;
  --left-end: 0vw;
  left: 13vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -9s;
  opacity: 0.7512;
}
.snowflake:nth-child(154) {
  --size: 0.2vw;
  --left-ini: -6vw;
  --left-end: 8vw;
  left: 50vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -9s;
  opacity: 0.0446;
}
.snowflake:nth-child(155) {
  --size: 0.5vw;
  --left-ini: 1vw;
  --left-end: -1vw;
  left: 70vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -9s;
  opacity: 0.3791;
}
.snowflake:nth-child(156) {
  --size: 0.5vw;
  --left-ini: 8vw;
  --left-end: 8vw;
  left: 90vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -2s;
  opacity: 0.2763;
}
.snowflake:nth-child(157) {
  --size: 0.2vw;
  --left-ini: -8vw;
  --left-end: -6vw;
  left: 26vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -6s;
  opacity: 0.2627;
}
.snowflake:nth-child(158) {
  --size: 0.4vw;
  --left-ini: -4vw;
  --left-end: -7vw;
  left: 51vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -1s;
  opacity: 0.7305;
}
.snowflake:nth-child(159) {
  --size: 0.3vw;
  --left-ini: -5vw;
  --left-end: -9vw;
  left: 1vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -6s;
  opacity: 0.4832;
}
.snowflake:nth-child(160) {
  --size: 0.2vw;
  --left-ini: -7vw;
  --left-end: 1vw;
  left: 65vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -3s;
  opacity: 0.1779;
}
.snowflake:nth-child(161) {
  --size: 0.3vw;
  --left-ini: 0vw;
  --left-end: -3vw;
  left: 97vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -3s;
  opacity: 0.9618;
}
.snowflake:nth-child(162) {
  --size: 0.4vw;
  --left-ini: 9vw;
  --left-end: 3vw;
  left: 91vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -7s;
  opacity: 0.0812;
}
.snowflake:nth-child(163) {
  --size: 0.5vw;
  --left-ini: 2vw;
  --left-end: 5vw;
  left: 19vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -10s;
  opacity: 0.3437;
}
.snowflake:nth-child(164) {
  --size: 0.2vw;
  --left-ini: 3vw;
  --left-end: 7vw;
  left: 33vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -7s;
  opacity: 0.6629;
}
.snowflake:nth-child(165) {
  --size: 0.2vw;
  --left-ini: -5vw;
  --left-end: -4vw;
  left: 1vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -10s;
  opacity: 0.2716;
}
.snowflake:nth-child(166) {
  --size: 0.4vw;
  --left-ini: 5vw;
  --left-end: 7vw;
  left: 67vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -9s;
  opacity: 0.9908;
}
.snowflake:nth-child(167) {
  --size: 0.4vw;
  --left-ini: 10vw;
  --left-end: -7vw;
  left: 53vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -7s;
  opacity: 0.1516;
}
.snowflake:nth-child(168) {
  --size: 0.3vw;
  --left-ini: -8vw;
  --left-end: 0vw;
  left: 52vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -10s;
  opacity: 0.913;
}
.snowflake:nth-child(169) {
  --size: 0.1vw;
  --left-ini: 6vw;
  --left-end: 10vw;
  left: 88vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -8s;
  opacity: 0.8929;
}
.snowflake:nth-child(170) {
  --size: 0.3vw;
  --left-ini: 3vw;
  --left-end: -9vw;
  left: 20vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -9s;
  opacity: 0.889;
}
.snowflake:nth-child(171) {
  --size: 0.5vw;
  --left-ini: 3vw;
  --left-end: 0vw;
  left: 38vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -4s;
  opacity: 0.0345;
}
.snowflake:nth-child(172) {
  --size: 0.3vw;
  --left-ini: 3vw;
  --left-end: -7vw;
  left: 21vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -9s;
  opacity: 0.1929;
}
.snowflake:nth-child(173) {
  --size: 0.1vw;
  --left-ini: -4vw;
  --left-end: -2vw;
  left: 54vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -3s;
  opacity: 0.66;
}
.snowflake:nth-child(174) {
  --size: 0.3vw;
  --left-ini: 3vw;
  --left-end: -3vw;
  left: 42vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -8s;
  opacity: 0.7842;
}
.snowflake:nth-child(175) {
  --size: 0.5vw;
  --left-ini: 0vw;
  --left-end: -8vw;
  left: 8vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -9s;
  opacity: 0.7412;
}
.snowflake:nth-child(176) {
  --size: 0.4vw;
  --left-ini: 10vw;
  --left-end: -1vw;
  left: 87vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -4s;
  opacity: 0.4386;
}
.snowflake:nth-child(177) {
  --size: 0.4vw;
  --left-ini: -4vw;
  --left-end: -3vw;
  left: 48vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -8s;
  opacity: 0.6566;
}
.snowflake:nth-child(178) {
  --size: 0.5vw;
  --left-ini: 2vw;
  --left-end: -4vw;
  left: 54vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -10s;
  opacity: 0.4426;
}
.snowflake:nth-child(179) {
  --size: 0.3vw;
  --left-ini: 6vw;
  --left-end: 8vw;
  left: 19vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -2s;
  opacity: 0.3398;
}
.snowflake:nth-child(180) {
  --size: 0.2vw;
  --left-ini: 7vw;
  --left-end: 5vw;
  left: 18vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -8s;
  opacity: 0.724;
}
.snowflake:nth-child(181) {
  --size: 0.4vw;
  --left-ini: -3vw;
  --left-end: 3vw;
  left: 65vw;
  animation: snowfall 14s linear infinite;
  animation-delay: -2s;
  opacity: 0.724;
}
.snowflake:nth-child(182) {
  --size: 0.4vw;
  --left-ini: 6vw;
  --left-end: -3vw;
  left: 52vw;
  animation: snowfall 15s linear infinite;
  animation-delay: -5s;
  opacity: 0.4264;
}
.snowflake:nth-child(183) {
  --size: 0.5vw;
  --left-ini: -5vw;
  --left-end: -6vw;
  left: 75vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -2s;
  opacity: 0.5768;
}
.snowflake:nth-child(184) {
  --size: 0.1vw;
  --left-ini: 0vw;
  --left-end: -3vw;
  left: 61vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -9s;
  opacity: 0.1475;
}
.snowflake:nth-child(185) {
  --size: 0.1vw;
  --left-ini: 7vw;
  --left-end: 5vw;
  left: 10vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -10s;
  opacity: 0.4378;
}
.snowflake:nth-child(186) {
  --size: 0.1vw;
  --left-ini: -1vw;
  --left-end: -6vw;
  left: 71vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -7s;
  opacity: 0.4902;
}
.snowflake:nth-child(187) {
  --size: 0.2vw;
  --left-ini: 5vw;
  --left-end: 0vw;
  left: 31vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -4s;
  opacity: 0.6052;
}
.snowflake:nth-child(188) {
  --size: 0.5vw;
  --left-ini: 10vw;
  --left-end: 6vw;
  left: 10vw;
  animation: snowfall 13s linear infinite;
  animation-delay: -5s;
  opacity: 0.6013;
}
.snowflake:nth-child(189) {
  --size: 0.5vw;
  --left-ini: 2vw;
  --left-end: 8vw;
  left: 90vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -1s;
  opacity: 0.0235;
}
.snowflake:nth-child(190) {
  --size: 0.3vw;
  --left-ini: 0vw;
  --left-end: -8vw;
  left: 7vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -6s;
  opacity: 0.7292;
}
.snowflake:nth-child(191) {
  --size: 0.3vw;
  --left-ini: -2vw;
  --left-end: 7vw;
  left: 45vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -5s;
  opacity: 0.7983;
}
.snowflake:nth-child(192) {
  --size: 0.4vw;
  --left-ini: 2vw;
  --left-end: 1vw;
  left: 62vw;
  animation: snowfall 9s linear infinite;
  animation-delay: -9s;
  opacity: 0.7326;
}
.snowflake:nth-child(193) {
  --size: 0.4vw;
  --left-ini: 1vw;
  --left-end: 3vw;
  left: 65vw;
  animation: snowfall 12s linear infinite;
  animation-delay: -5s;
  opacity: 0.3324;
}
.snowflake:nth-child(194) {
  --size: 0.2vw;
  --left-ini: -9vw;
  --left-end: -2vw;
  left: 61vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -3s;
  opacity: 0.0499;
}
.snowflake:nth-child(195) {
  --size: 0.3vw;
  --left-ini: -5vw;
  --left-end: 0vw;
  left: 86vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -1s;
  opacity: 0.4209;
}
.snowflake:nth-child(196) {
  --size: 0.4vw;
  --left-ini: -6vw;
  --left-end: 4vw;
  left: 16vw;
  animation: snowfall 8s linear infinite;
  animation-delay: -4s;
  opacity: 0.8568;
}
.snowflake:nth-child(197) {
  --size: 0.5vw;
  --left-ini: -2vw;
  --left-end: -9vw;
  left: 35vw;
  animation: snowfall 6s linear infinite;
  animation-delay: -7s;
  opacity: 0.7037;
}
.snowflake:nth-child(198) {
  --size: 0.1vw;
  --left-ini: 10vw;
  --left-end: -6vw;
  left: 6vw;
  animation: snowfall 7s linear infinite;
  animation-delay: -7s;
  opacity: 0.1554;
}
.snowflake:nth-child(199) {
  --size: 0.4vw;
  --left-ini: 3vw;
  --left-end: -7vw;
  left: 100vw;
  animation: snowfall 10s linear infinite;
  animation-delay: -3s;
  opacity: 0.266;
}
.snowflake:nth-child(200) {
  --size: 0.3vw;
  --left-ini: 2vw;
  --left-end: -1vw;
  left: 66vw;
  animation: snowfall 11s linear infinite;
  animation-delay: -7s;
  opacity: 0.9052;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }

  .score-detail-player-info .player-name {
    font-size: 2rem
  }

  .beatmap-title {
    font-size: 1.2rem;
  }

  .score-detail {
    flex-direction: column;
  }

  .navbar {
    flex-direction: column;
  }

  .navbar_2 {
    flex-direction: row !important;
  }

  .navbar-left {
    justify-content: center;
    padding: 0;
  }

  .navbar-right {
    justify-content: center;
    padding: 0;
    padding-bottom: 0.5rem;
  }

  .score-detail .score-detail-score {
    font-size: 1.5rem;
    text-align: center;
  }

  .beatmap-difficulty-icon {
    width: 2rem;
    height: 2rem;
  }

  .manual-update {
    margin-top: 4rem;
  }

  table {
    font-size: 0.7rem;
  }

  .secondary-td {
    display: none;
    /* overflow: hidden; */
    /* width: 0px; */
  }
}